<template>
  <v-bottom-sheet
    class="font font-weight-medium"
    inset
    v-model="state"
    scrollable
    persistent
  >
    <v-card tile>
      <div v-if="details" class="d-flex flex-row flex-grow-1">
        <div class="d-flex flex-column flex-grow-1">
          <span class="font-weight-bold font-size-md ft mx-1 px-3 pt-3"
            >Generate Deliveries</span
          >
          <span class="font-weight-bold text-sm ft mx-1 px-3 pb-1 pt-1"
            >Order Reference : {{ details.orderReference }}
          </span>
          <span
            v-if="details && details.productId"
            class="font-weight-bold text-sm ft mx-1 px-3 pb-1 pt-1"
            >Product Name : {{ details.productId.name }}
          </span>
          <span
            v-if="details.carId"
            class="font-weight-bold ft text-sm ft mx-1 px-3 pb-1"
            >Vehicle Number : {{ details.carId.registrationNumber }}
          </span>
          <span class="font-weight-bold ft text-sm ft mx-1 px-3 pb-1"
            >Trip Number :
            {{ details.tripNumber ? details.tripNumber : "No trip number" }}
          </span>
          <span class="font-weight-bold text-sm ft mx-1 px-3 pb-2"
            >Date : {{ details.date | timestamp("Do MMMM, YYYY") }}
          </span>
        </div>
        <div class="d-flex flex-column flex-grow-1 mx-3">
          <span class="font-weight-bold ft text-sm ft mx-1 px-3 pt-4"
            >Loading Date :
            <span v-if="details.loadingDate">{{
              details.loadingDate | timestamp("Do MMMM, YYYY")
            }}</span
            ><span v-else>{{ "No Loading Date" }}</span>
          </span>
          <span
            v-if="details && details.orderNumber"
            class="font-weight-bold text-sm ft mx-1 px-3 pb-1 pt-1"
            >Vivo Order Number : {{ details.orderNumber }}
          </span>
          <span class="font-weight-bold ft text-sm ft mx-1 px-3 pt-1 pb-1"
            >Cheque Number :
            {{
              details.chequeId ? details.chequeId.number : "No cheque number"
            }}
          </span>
          <span
            v-if="details.chequeId"
            class="font-weight-bold ft text-sm ft mx-1 px-3 pb-1"
            >Cheque Amount :
            {{
              details.chequeId.amount
                | currencyFormat(currency.length > 0 ? currency[0].code : "GHS")
            }}
          </span>
        </div>

        <v-btn @click.stop="close" small right top absolute fab color="primary">
          <i class="material-icons">close</i>
        </v-btn>
      </div>
      <template v-if="pageLoading">
        <SkeletonPreloader type="table" />
      </template>
      <ValidationObserver v-else v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(createDeliveries)">
          <div
            class="d-flex flex-column flex-grow-1"
            style="width: 100%;overflow-y: scroll;height: 100%;"
          >
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex flex-column flex-grow-1 px-2 pb-4">
                <div class="d-flex flex-column flex-grow-1 ">
                  <div v-if="lineItem" class="d-flex flex-column flex-grow-1 ">
                    <div class="d-flex flex-row flex-grow-1 px-2">
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-column flex-grow-1"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-autocomplete
                          dense
                          outlined
                          :items="branches"
                          :error-messages="errors[0]"
                          item-value="id"
                          item-text="name"
                          :readonly="!lineItem.isNewLineItem"
                          label="Branch"
                          v-model="lineItem.branchId"
                          class=" ft font-weight-medium font-size-sm ma-1"
                          style="flex-basis: 22%"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-column flex-grow-1"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-autocomplete
                          dense
                          outlined
                          :items="products"
                          item-value="id"
                          item-text="name"
                          :error-messages="errors[0]"
                          readonly
                          label="Product"
                          v-model="lineItem.productId"
                          class=" ft font-weight-medium font-size-sm ma-1"
                        />
                      </ValidationProvider>

                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|numeric"
                        tag="div"
                        class="d-flex flex-column flex-grow-1"
                      >
                        <v-text-field
                          dense
                          outlined
                          :error-messages="errors[0]"
                          v-model="lineItem.quantity"
                          class=" ft font-weight-medium font-size-sm ma-1"
                          label="Quantity (Litres)"
                          :readonly="!lineItem.isNewLineItem"
                        />
                      </ValidationProvider>
                    </div>
                    <v-row>
                      <v-col
                        cols="12"
                        style="overflow-y: scroll;max-height: 500px;"
                      >
                        <div
                          v-if="sharedProductsAndQuantity.length > 0"
                          class="d-flex flex-column flex-grow-1  generate-order"
                        >
                          <h4 class="pb-2 ft font-weight-bold">
                            Quantity for each branch
                          </h4>
                          <div
                            v-for="(subLineItem,
                            index) in sharedProductsAndQuantity"
                            :key="index"
                            class="d-flex flex-row flex-grow-1"
                          >
                            <ValidationProvider
                              tag="div"
                              class="d-flex flex-column flex-grow-1"
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <v-autocomplete
                                dense
                                outlined
                                :items="subLineItem.branchList"
                                :error-messages="errors[0]"
                                item-value="id"
                                item-text="name"
                                :readonly="!subLineItem.isNewLineItem"
                                label="Branch"
                                v-model="subLineItem.branchId"
                                class=" ft font-weight-medium font-size-sm ma-1"
                                style="flex-basis: 22%"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              tag="div"
                              class="d-flex flex-column flex-grow-1"
                              v-slot="{ errors }"
                              rules="required"
                            >
                              <v-autocomplete
                                dense
                                outlined
                                :items="products"
                                item-value="id"
                                item-text="name"
                                :error-messages="errors[0]"
                                readonly
                                label="Product"
                                v-model="subLineItem.productId"
                                class=" ft font-weight-medium font-size-sm ma-1"
                              />
                            </ValidationProvider>

                            <ValidationProvider
                              v-slot="{ errors }"
                              rules="required|numeric"
                              tag="div"
                              class="d-flex flex-column flex-grow-1"
                            >
                              <v-text-field
                                dense
                                outlined
                                :error-messages="errors[0]"
                                v-model="subLineItem.quantity"
                                class=" ft font-weight-medium font-size-sm ma-1"
                                label="Quantity (Litres)"
                                :readonly="!subLineItem.isNewLineItem"
                              />
                            </ValidationProvider>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-if="sharedProductsAndQuantity.length > 0"
                                  v-on="on"
                                  fab
                                  small
                                  @click="removeLineItem(index)"
                                  class="mt-1"
                                  color="red"
                                  icon
                                  ><i class="material-icons font-size-md"
                                    >close</i
                                  ></v-btn
                                >
                              </template>
                              <span class="ft font-weight-medium"
                                >Remove Item</span
                              >
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <div class="d-flex flex-column flex-grow-1">
                      <div class="d-flex flex-column flex-grow-1">
                        <div
                          class="d-flex flex-row pb-2"
                          style="justify-content: flex-end;"
                        >
                          <v-btn
                            small
                            color="primary"
                            text
                            :disabled="
                              parseInt(trackLineItemQuantity) -
                                sharedProductsAndQuantity.reduce(
                                  (acc, item) => {
                                    return (acc += isNaN(
                                      parseInt(item.quantity)
                                    )
                                      ? 0
                                      : parseInt(item.quantity));
                                  },
                                  0
                                ) <
                                0 || loading
                            "
                            @click="pushToLineItem(lineItem)"
                          >
                            <i class="material-icons font-size-md ml-n2">add</i>
                            <span class="ft font-weight-medium font-size-sm"
                              >Share Quantity</span
                            >
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-row mr-2 mt-2"
                  style="justify-content: flex-end;"
                >
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="
                      loading ||
                        sharedProductsAndQuantity.length === 0 ||
                        parseInt(trackLineItemQuantity) -
                          sharedProductsAndQuantity.reduce((acc, item) => {
                            return (acc += isNaN(parseInt(item.quantity))
                              ? 0
                              : parseInt(item.quantity));
                          }, 0) <
                          0
                    "
                    :loading="loading"
                  >
                    <span class="ft">Generate Delivery</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
/* eslint-disable no-unused-vars */
import { timestamp } from "../../../../../filters/timestamp";
import { currencyFormat } from "../../../../../filters/currencyFormat";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field must be numeric"
});
import { mapGetters } from "vuex";
import { numeric, required } from "vee-validate/dist/rules";
export default {
  name: "OrderDetailsBottomSheet",
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: () =>
      import(`../../../../../components/SkeletonPreloader`)
  },
  props: {
    details: {
      type: Object,
      default: null
    },
    state: Boolean,
    loading: Boolean,
    pageLoading: Boolean,
    currency: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    timestamp,
    currencyFormat
  },
  computed: {
    ...mapGetters({
      branches: "orders/getAllBranches",
      products: "orders/getAllProducts",
      resetFormState: "getResetFormState",
      resetFormValues: "orders/getResetFormValues"
    })
  },
  data: () => ({
    orderLineItems: [],
    lineItem: null,
    sharedProductsAndQuantity: [],
    trackLineItemQuantity: null,
    originalLineItemQuantity: null,
    filteredBranches: [],
    headers: [
      { text: "Branch", value: "branchId" },
      { text: "Product", value: "productId" },
      { text: "Quantity (Ltr)", value: "quantity" }
    ]
  }),
  watch: {
    resetFormState(state) {
      if (state) {
        this.sharedProductsAndQuantity = [...this.resetFormValues];
      }
      this.$store.dispatch("resetFormState", false, { root: true });
    },
    details(payload) {
      this.lineItem = { ...payload, isNewLineItem: false };
      this.trackLineItemQuantity = this.originalLineItemQuantity = parseInt(
        this.lineItem.quantity
      );
      this.filteredBranches = this.branches;
    },
    sharedProductsAndQuantity: {
      handler(payload) {
        // quantity calculator
        if (payload.length > 0) {
          payload.forEach(() => {
            this.lineItem.quantity =
              parseInt(this.trackLineItemQuantity) -
              payload.reduce((acc, item) => {
                return (acc += isNaN(parseInt(item.quantity))
                  ? 0
                  : parseInt(item.quantity));
              }, 0);
          });
        } else {
          this.lineItem.quantity = parseInt(this.originalLineItemQuantity);
        }
      },
      deep: true
    }
  },
  methods: {
    pushToLineItem({ productId }) {
      this.sharedProductsAndQuantity.push({
        productId: productId.id,
        branchList: this.branches.filter(
          branch =>
            !this.sharedProductsAndQuantity
              .map(sharedItems => sharedItems.branchId)
              .includes(branch.id)
        ),
        quantity: "",
        branchId: "",
        isNewLineItem: true
      });
    },
    removeLineItem(index) {
      this.sharedProductsAndQuantity.splice(index, 1);
    },
    close() {
      this.$emit("actions", "generate");
    },
    createDeliveries() {
      let { quantity, branchId, productId, id, orderId } = this.lineItem;
      let payload = {
        date: this.details.date,
        orderId: this.lineItem.orderId.id,
        remainingQuantity: {
          quantity,
          branchId: branchId.id,
          productId: productId.id,
          orderLineItemId: id,
          orderId: orderId.id
        },
        deliveriesLineItems: this.sharedProductsAndQuantity.map(
          ({ branchList, ...rest }) => {
            return {
              ...rest,
              quantity: parseInt(rest.quantity),
              orderLineItemId: this.lineItem.id
            };
          }
        )
      };
      this.$emit("deliveries", {
        body: payload,
        dialog: { idx: "generate", state: false }
      });
    }
  }
};
</script>
